import { devices } from '@src/styles/theme';
import styled from 'styled-components';

type Props = {
  children?: React.ReactNode;
  backgroundColor?: string;
  paddingBottom?: string;
  paddingTop?: string;
  padding?: string;
  display?: string;
  flexDirection?: string;
  alignItems?: string;
  justifyContent?: string;
  height?: string;
  background?: string;
  opacity?: string;
};

const CMSection = ({
  children,
  backgroundColor,
  paddingBottom,
  padding,
  display,
  flexDirection,
  alignItems,
  justifyContent,
  height,
  paddingTop,
  background,
  opacity,
}: Props) => {
  return (
    <Section
      backgroundColor={backgroundColor}
      paddingBottom={paddingBottom}
      padding={padding}
      display={display}
      flexDirection={flexDirection}
      alignItems={alignItems}
      justifyContent={justifyContent}
      height={height}
      paddingTop={paddingTop}
      background={background}
      opacity={opacity}
    >
      {children}
    </Section>
  );
};

const Section = styled.section<Props>`
  background-color: ${(props) => props.backgroundColor};
  background: ${(props) => props.background};
  padding-bottom: ${(props) => props.paddingBottom};
  padding: ${(props) => props.padding};
  display: ${(props) => props.display};
  flex-direction: ${(props) => props.flexDirection};
  align-items: ${(props) => props.alignItems};
  justify-content: ${(props) => props.justifyContent};
  height: ${(props) => props.height};
  padding-top: ${(props) => props.paddingTop};
  opacity: ${(props) => props.opacity};
  @media ${devices.tablet} {
    /* padding-top: 50px; */
  }
`;

export default CMSection;
