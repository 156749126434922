import {
  IContent,
  IFlexWrap,
  IImageWrap,
  TAbsolute,
} from '@src/interfaces/Introduction';
import styled from 'styled-components';
import React, { useEffect } from 'react';
import { devices, theme } from '@src/styles/theme';
import { FiArrowRight } from 'react-icons/fi';
import CMSection from '@src/components/Atoms/CMSection';
import Image from 'next/image';

const BoardContent = () => {
  return (
    <CMSection backgroundColor={theme.commonColor.white}>
      <ContentWrap
        width="100%"
        textAlign="left"
        margin="0 auto"
        position="relative"
        height="896px"
      >
        <SemiWrap>
          <SmallTitle>커뮤니티</SmallTitle>
          <BigTitle>
            당신의 이야기를 <br /> 들려주세요
          </BigTitle>
          <div style={{ position: 'relative' }} data-aos="fade-right">
            <MobileImg
              width={187}
              height={403}
              alt="mobile-community-image"
              src={'/imgs/info/MobileLeft.svg'}
            />
            <MobileImg
              width={206}
              height={373}
              alt="mobile-community-image2"
              style={{
                position: 'absolute',
                top: '0%',
                left: '45%',
              }}
              src={'/imgs/info/secondMobileRight2.svg'}
            />
          </div>
          <FlexWrap>
            <SmallDescription href={'/community/board'}>
              헌혈의 집, 카페 리뷰부터 소소한 이야기까지! <br />
              당신의 헌혈 이야기를 나눠보세요
            </SmallDescription>
            {/* TODO: 위아래 정렬맞추기 */}
            <ArrowRight />
          </FlexWrap>
          <AbsoluteImage data-aos="fade-right" left="40%" top="7%">
            <PcImg
              src={'/imgs/info/secondPc.png'}
              width={740.096}
              height={790.459}
              alt={'community-image'}
            />
          </AbsoluteImage>
        </SemiWrap>
      </ContentWrap>
    </CMSection>
  );
};

const ArrowRight = styled(FiArrowRight)`
  margin-left: 8px;
  cursor: pointer;
  @media ${devices.tablet} {
    display: none;
  }
`;

const MobileImg = styled(Image)`
  display: none;
  @media ${devices.tablet} {
    display: block;
  }
`;

const PcImg = styled(Image)`
  width: 741.096px;
  height: 790.459px;
  @media ${devices.tablet} {
    display: none;
  }
`;
const AbsoluteImage = styled.div<TAbsolute>`
  position: absolute;
  left: ${(props) => props.left};
  top: ${(props) => props.top};
  right: ${(props) => props.right};
  bottom: ${(props) => props.bottom};
  z-index: ${(props) => props.zIndex};
  transform: ${(props) => props.transform};
  display: block;
  @media ${devices.tablet} {
    display: none;
    width: 100%;
    height: 100%;
    z-index: -9999;
  }
`;

const ContentWrap = styled.div<IContent>`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  padding: ${(props) => props.padding};
  display: ${(props) => props.display};
  gap: ${(props) => props.gap};
  background-color: ${(props) => props.backgroundColor};
  margin: ${(props) => props.margin};
  text-align: ${(props) => props.textAlign};
  margin: ${(props) => props.margin};
  border-radius: ${(props) => props.borderRaduis};
  justify-content: ${(props) => props.justifyContent};
  align-items: ${(props) => props.alignItems};
  overflow: ${(props) => props.overFlow};
  position: ${(props) => props.position};
  @media ${devices.tablet} {
    width: 100%;
    height: 100%;
    position: relative;
  }
`;

const SmallTitle = styled.div`
  font-size: 22px;
  color: ${({ theme }) => theme.commonColor.pink004};
  font-weight: 700;
  font-family: 'Regular';
  @media ${devices.tablet} {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 18.587px; /* 103.261% */
    letter-spacing: -0.372px;
    margin-bottom: 10px;
  }
`;

const FlexWrap = styled.div`
  margin-top: 65px;
  @media ${devices.tablet} {
    margin-top: 25px;
  }
`;

const SmallDescription = styled.a`
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 160% */
  letter-spacing: -0.3px;
  font-family: 'Regular';
  cursor: pointer;
  @media ${devices.tablet} {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: -0.6px;
  }
`;

const BigTitle = styled.div`
  margin-top: 24px;
  color: #343434;
  font-size: 47px;
  font-style: normal;
  font-weight: 500;
  line-height: 65px; /* 138.298% */
  letter-spacing: -1.41px;
  font-family: 'NOTO';
  @media ${devices.tablet} {
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 38px; /* 135.714% */
    letter-spacing: -0.84px;
  }
`;

const SemiWrap = styled.div`
  position: relative;
  width: 1200px;
  margin: 0 auto;
  padding-top: 120px;
  @media ${devices.tablet} {
    width: 100%;
    padding: 80px 20px;
  }
`;

export default BoardContent;
