import Meta from '@src/components/Meta';
import IntroductionList from '@src/components/Organism/Introduction/IntroductionList';
import { metaInfo } from '@src/data/meta';

export default function Home() {
  const { introduction } = metaInfo;
  return (
    <>
      <Meta
        title={introduction.title}
        description={introduction.description}
        ogImage={introduction.ogImage}
      />
      <IntroductionList />
    </>
  );
}
