import React, { useEffect } from 'react';
import AppInfoContent from '@src/components/Molecules/Introduction/AppInfoContent';
import BoardContent from '@src/components/Molecules/Introduction/BoardContent';
import DonationContent from '@src/components/Molecules/Introduction/DonationContent';
import BloodReviewContent from '@src/components/Molecules/Introduction/BloodReviewContent';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { IContent } from '@src/interfaces/community';
import { IBloodReview } from '@src/interfaces/blood';
import SixthContent from '@src/components/Molecules/Introduction/SixthContent';
import MyPageContent from '@src/components/Molecules/Introduction/MyPageContent';
import LiveBloodContent from '@src/components/Molecules/Introduction/LiveBloodContent';
import PpleStoreContent from '@src/components/Molecules/Introduction/PpleStoreContent';
import AppTitleContent from '@src/components/Molecules/Introduction/AppTitleContent';

const IntroductionList = () => {
  useEffect(() => {
    AOS.init({ once: true });
  }, []);
  return (
    <>
      {/* <AppInfoContent /> */}
      <AppTitleContent />
      <BoardContent />
      <DonationContent />
      {/* <BloodReviewContent mainBloodReviews={mainBloodReviews} /> */}
      <MyPageContent />
      <LiveBloodContent />
      {/* <PpleStoreContent /> */}
      <SixthContent />
    </>
  );
};

export default IntroductionList;
