const ogDefault = '/imgs/ogMain.jpg';
const ogBoard = '/imgs/ogBoard.png';
export const metaInfo = {
  introduction: {
    title: '세상을 구하는 따뜻한 인연 피플',
    description:
      '피가 부족해 위급한 순간, 도움을 요청하고 어려움에 도움을 주고 싶을 때',
    ogImage: ogDefault,
  },
  donation: {
    title: '헌혈요청',
    description: '헌혈요청 게시판',
    ogImage: ogBoard,
  },
  board: {
    title: '커뮤니티',
    description: '커뮤니티 게시판',
    ogImage: ogDefault,
  },
  notice: {
    title: '공지사항',
    description: '공지사항',
    ogImage: ogDefault,
  },
  auth: {
    title: '로그인',
    description: '로그인',
    ogImage: ogDefault,
  },
  my: {
    title: '마이페이지',
    description: '마이페이지',
    ogImage: ogDefault,
  },
  news: {
    title: '언론보도',
    description: '언론보도',
    ogImage: ogDefault,
  },
  sponser: {
    title: '후원',
    description: '후원',
    ogImage: ogDefault,
  },
  bloodHomeReview: {
    title: '헌혈리뷰',
    description: '헌혈리뷰',
    ogImage: ogDefault,
  },
};
