import CMMainText from '@src/components/Atoms/CMMainText';
import CMSection from '@src/components/Atoms/CMSection';
import {
  IContent,
  IImageWrap,
  TAbsolute,
  TFlexITem,
} from '@src/interfaces/Introduction';
import { IContent as DContent } from '@src/interfaces/community';
import { devices, theme } from '@src/styles/theme';
import React from 'react';
import styled from 'styled-components';
import { FiArrowRight } from 'react-icons/fi';
import Image from 'next/image';

const MyPageContent = () => {
  return (
    <CMSection backgroundColor="#fff">
      <ContentWrap
        width="1200px"
        textAlign="left"
        margin="0 auto"
        position="relative"
        height="1186px"
        backgroundColor="#fff"
      >
        <SemiWrap>
          <SmallTitle>마이페이지</SmallTitle>
          <BigTitle>
            헌혈에 즐거움을 <br /> 더해보세요
          </BigTitle>
          <div style={{ position: 'relative' }} data-aos="fade-right">
            <MobileImg
              width={226}
              height={400}
              alt={'mobile-left'}
              src={'/imgs/info/fourthMobLeft.svg'}
            />
            <MobileImg
              width={227}
              height={400}
              alt={'mobile-right'}
              style={{
                position: 'absolute',
                top: '15%',
                right: -13,
              }}
              src={'/imgs/info/fourthMobRight.svg'}
            />
          </div>
          <FlexWrap>
            <SmallDescription href={'/my'}>
              활동 배지, 헌혈 스토리 등의 컨텐츠를 통해 <br />
              헌혈에 재미를 느껴보세요
            </SmallDescription>
            <ArrowRight />
          </FlexWrap>
          <MyWrap>
            <MyWraper>
              <MobileImg
                width={45}
                height={45}
                alt={'mobile-badge'}
                src={'/imgs/info/fourthMobileBadge.svg'}
              />
              <MyCategoryTitle>활동 배지</MyCategoryTitle>
              <MyCategoryDescription>
                피플 앱 활동을 통해 <br /> 배지를 수집해요
              </MyCategoryDescription>
            </MyWraper>
            <MyWraper>
              <MobileImg
                width={45}
                height={45}
                alt={'mobile-letter'}
                src={'/imgs/info/fourthMobileLetter.svg'}
              />
              <MyCategoryTitle>감사메세지</MyCategoryTitle>
              <MyCategoryDescription>
                도움을 주고 감사한 마음이 <br /> 담긴 메세지를 모아보세요
              </MyCategoryDescription>
            </MyWraper>
            <MyWraper>
              <MobileImg
                width={45}
                height={45}
                alt={'mobile-point'}
                src={'/imgs/info/fourthMobilePoint.svg'}
              />
              <MyCategoryTitle>피플포인트</MyCategoryTitle>
              <MyCategoryDescription>
                도움을 주어 포인트를 모아 <br /> 상품을 구매해요
              </MyCategoryDescription>
            </MyWraper>
            <MyWraper>
              <MobileImg
                width={45}
                height={45}
                alt={'mobile-certification'}
                src={'/imgs/info/fourthMobileCertification.svg'}
              />
              <MyCategoryTitle>헌혈 인증</MyCategoryTitle>
              <MyCategoryDescription>
                헌혈증 모아 인증하며 <br /> 뿌듯함을 느껴보세요
              </MyCategoryDescription>
            </MyWraper>
          </MyWrap>
          <MyWrap>
            <MyPcWraper>
              <PcImg
                width={62}
                height={62}
                alt={'badge'}
                src={'/imgs/info/fourthBadge.svg'}
              />
              <MyCategoryTitle>활동 배지</MyCategoryTitle>
              <MyCategoryDescription>
                피플 앱 활동을 통해 <br /> 배지를 수집해요
              </MyCategoryDescription>
            </MyPcWraper>
            <MyPcWraper>
              <PcImg
                width={62}
                height={62}
                alt={'letter'}
                src={'/imgs/info/fourthLetter.svg'}
              />
              <MyCategoryTitle>감사메세지</MyCategoryTitle>
              <MyCategoryDescription>
                도움을 주고 감사한 마음이 <br /> 담긴 메세지를 모아보세요
              </MyCategoryDescription>
            </MyPcWraper>
            <MyPcWraper>
              <PcImg
                width={65}
                height={65}
                alt={'point'}
                src={'/imgs/info/fourthPoint.svg'}
              />
              <MyCategoryTitle>피플포인트</MyCategoryTitle>
              <MyCategoryDescription>
                도움을 주어 포인트를 모아 <br /> 상품을 구매해요
              </MyCategoryDescription>
            </MyPcWraper>
            <MyPcWraper>
              <PcImg
                width={65}
                height={65}
                alt={'certification'}
                src={'/imgs/info/fourthCertification.svg'}
              />
              <MyCategoryTitle>헌혈 인증</MyCategoryTitle>
              <MyCategoryDescription>
                헌혈증 모아 인증하며 <br /> 뿌듯함을 느껴보세요
              </MyCategoryDescription>
            </MyPcWraper>
          </MyWrap>
          <AbsoluteImage data-aos="fade-right" left="55%" top="10%">
            <PcImg
              width={702}
              height={1004}
              alt={'phone'}
              src={'/imgs/info/fourthPhone.png'}
            />
          </AbsoluteImage>
        </SemiWrap>
      </ContentWrap>
    </CMSection>
  );
};

const ArrowRight = styled(FiArrowRight)`
  margin-left: 8px;
  @media ${devices.tablet} {
    display: none;
  }
`;

const ContentWrap = styled.div<IContent>`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  padding: ${(props) => props.padding};
  display: ${(props) => props.display};
  gap: ${(props) => props.gap};
  background-color: ${(props) => props.backgroundColor};
  margin: ${(props) => props.margin};
  text-align: ${(props) => props.textAlign};
  margin: ${(props) => props.margin};
  border-radius: ${(props) => props.borderRaduis};
  justify-content: ${(props) => props.justifyContent};
  align-items: ${(props) => props.alignItems};
  overflow: ${(props) => props.overFlow};
  position: ${(props) => props.position};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  @media ${devices.tablet} {
    width: 100%;
    height: 1143px;
  }
`;

const AbsoluteImage = styled.div<TAbsolute>`
  position: absolute;
  left: ${(props) => props.left};
  top: ${(props) => props.top};
  right: ${(props) => props.right};
  bottom: ${(props) => props.bottom};
  z-index: ${(props) => props.zIndex};
  transform: ${(props) => props.transform};
  @media ${devices.tablet} {
    width: 100%;
    height: 100%;
    z-index: -9999;
    display: none;
  }
`;

const MobileImg = styled(Image)`
  display: none;
  @media ${devices.tablet} {
    display: block;
  }
`;

const PcImg = styled(Image)`
  display: block;
  @media ${devices.tablet} {
    display: none;
  }
`;

const SmallTitle = styled.div`
  color: ${({ theme }) => theme.commonColor.pink004};
  font-family: 'Regular';
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px; /* 125% */
  letter-spacing: -0.6px;
  @media ${devices.tablet} {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 18.587px; /* 103.261% */
    letter-spacing: -0.372px;
    margin-bottom: 10px;
  }
`;

const FlexWrap = styled.div`
  margin-top: 65px;
`;

const SmallDescription = styled.a`
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 160% */
  letter-spacing: -0.3px;
  cursor: pointer;
  font-family: 'Regular';
  @media ${devices.tablet} {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: -0.6px;
  }
`;

const BigTitle = styled.div`
  margin-top: 24px;
  color: #343434;
  font-size: 47px;
  font-style: normal;
  font-weight: 500;
  line-height: 65px; /* 138.298% */
  letter-spacing: -1.41px;
  font-family: 'NOTO';
  @media ${devices.tablet} {
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 38px; /* 135.714% */
    letter-spacing: -0.84px;
    margin-bottom: 20px;
  }
`;

const MyWrap = styled.div`
  /* display: flex;
  flex-wrap: wrap; */
  max-width: 400px;
  gap: 60px;
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2개의 열을 가진 그리드 */
  grid-template-rows: repeat(2, 1fr); /* 2개의 행을 가진 그리드 */
  margin-top: 20px;
  @media ${devices.tablet} {
    gap: 0px;
    margin-top: 0px;
    max-width: 100%;
  }
`;

const MyPcWraper = styled.div`
  flex: 1;
  display: block;
  @media ${devices.tablet} {
    display: none;
    gap: 0px;
    margin-top: 45px;
  }
`;

const MyWraper = styled.div`
  flex: 1;
  display: none;
  @media ${devices.tablet} {
    display: block;
    gap: 0px;
    margin-top: 45px;
  }
`;

const MyCategoryTitle = styled.div`
  color: ${({ theme }) => theme.commonColor.black};
  font-family: 'Regular';
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 133.333% */
  letter-spacing: -0.6px;
  margin: 10px 0px;
  @media ${devices.tablet} {
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.39px;
  }
`;

const MyCategoryDescription = styled.div`
  color: ${({ theme }) => theme.commonColor.black};
  font-family: 'Regular';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: -0.6px;
  @media ${devices.tablet} {
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.39px;
  }
`;

const SemiWrap = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding-top: 120px;
  @media ${devices.tablet} {
    width: 100%;
    padding: 80px 20px;
  }
`;

export default MyPageContent;
