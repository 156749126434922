import CMSection from '@src/components/Atoms/CMSection';
import { devices, theme } from '@src/styles/theme';
import React from 'react';
import styled from 'styled-components';
import StoreBtn from '@src/components/Molecules/Store/StoreBtn';
import Image from 'next/image';

const AppTitleContent = () => {
  return (
    <CMSection>
      <ContentWrap>
        <PcTitleImage
          fill={true}
          src={'/imgs/info/title5.svg'}
          alt="pcTitleImage"
        />
        <MobileTitleImage
          fill={true}
          src={'/imgs/info/firstMobile2.png'}
          alt="mobileTitleImage"
        />
        <SemiWrap>
          <BigTitle>
            세상을 구하는 따뜻한 인연
            <br />{' '}
            <span
              style={{
                color: theme.commonColor.pink004,
              }}
            >
              피플
            </span>
          </BigTitle>
          <FlexWrap>
            <SmallDescription>
              수혈자는 사연을 올리고 빠르게 수혈받아요
              <br />
              헌혈자는 앱으로 헌혈을 인증하고 소통해요
            </SmallDescription>
          </FlexWrap>
        </SemiWrap>
        <StoreWrap>
          <StoreBtn isMobileColumn={true} />
        </StoreWrap>
      </ContentWrap>
    </CMSection>
  );
};

const PcTitleImage = styled(Image)`
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1001;
  object-fit: cover;
  @media ${devices.tablet} {
    display: none;
  }
`;

const MobileTitleImage = styled(Image)`
  display: none;
  @media ${devices.tablet} {
    display: block;
    object-fit: cover;
  }
`;

const ContentWrap = styled.div`
  position: relative;
  z-index: 47;
  padding-top: 192px;
  padding-bottom: 147px;
  height: 624px;
  @media ${devices.tablet} {
    padding: unset;
    height: 737px;
  }
`;

const SemiWrap = styled.div`
  position: relative;
  width: 1200px;
  margin: 0 auto;
  z-index: 12312312312;
  @media ${devices.tablet} {
    width: 100%;
    padding: 20px;
    padding-top: 35px;
  }
`;

const FlexWrap = styled.div`
  margin-top: 25px;
`;

const SmallDescription = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: -0.6px;
  font-family: 'Regular';
  color: ${({ theme }) => theme.commonColor.black};
  @media ${devices.tablet} {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: -0.6px;
  }
`;

const BigTitle = styled.div`
  color: ${({ theme }) => theme.commonColor.black};
  font-size: 47px;
  font-style: normal;
  font-weight: 700;
  line-height: 60px;
  position: relative;
  z-index: 999999999;
  font-family: 'NOTO';
  @media ${devices.tablet} {
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: 42px; /* 140% */
    letter-spacing: -0.6px;
  }
`;

const StoreWrap = styled.div`
  position: relative;
  z-index: 999999898989;
  margin: 0 auto;
  width: 1200px;
  margin-top: 45px;
  @media ${devices.tablet} {
    position: absolute;
    bottom: 10%;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    margin: 0 auto;
  }
`;

export default AppTitleContent;
