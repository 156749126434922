import Head from 'next/head';
import React from 'react';

type Props = {
  title: string;
  description: string;
  ogImage: string;
};

const Meta = ({ title, description, ogImage }: Props) => {
  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={ogImage} />
    </Head>
  );
};

export default Meta;
