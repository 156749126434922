import CMMainText from '@src/components/Atoms/CMMainText';
import CMSection from '@src/components/Atoms/CMSection';
import {
  IContent,
  IImageWrap,
  TAbsolute,
  TFlexITem,
} from '@src/interfaces/Introduction';
import { IContent as DContent } from '@src/interfaces/community';
import { devices, theme } from '@src/styles/theme';
import React from 'react';
import styled from 'styled-components';
import { FiArrowRight } from 'react-icons/fi';
import Image from 'next/image';

const LiveBloodContent = () => {
  return (
    <CMSection backgroundColor="#FFF">
      <ContentWrap
        width="100%"
        textAlign="left"
        margin="0 auto"
        // height="882px"
      >
        <SemiWrap>
          <SmallTitle>혈액수치</SmallTitle>
          <BigTitle>
            오늘의 혈액 수치를 <br /> 체크하세요
          </BigTitle>
          <SmallDescription>
            피플 앱에서 간편하게 매일매일 그날의
            <br /> 혈액 보유량을 확인할 수 있어요
          </SmallDescription>
          <MobileImgWrap data-aos="fade-right">
            <MobileImg
              width={221}
              height={226}
              alt={'mobile-blood-image'}
              style={{
                position: 'absolute',
                zIndex: 1,
                top: '25%',
              }}
              src={'/imgs/info/fifthMobileLeft.svg'}
            />
            <MobileImg
              width={217}
              height={219}
              alt={'mobile-blood-image-right'}
              style={{
                position: 'absolute',
                top: '-15%',
                right: 0,
                zIndex: 0,
              }}
              src={'/imgs/info/fifthMobileRight.svg'}
            />
          </MobileImgWrap>
          <AbsoluteImage data-aos="fade-right">
            <PcImg
              width={788}
              height={640}
              alt={'blood-image'}
              src={'/imgs/info/fifthPc.svg'}
            />
          </AbsoluteImage>
          <MobileSmallDescription>
            피플 앱에서 간편하게 매일매일 그날의
            <br /> 혈액 보유량을 확인할 수 있어요
          </MobileSmallDescription>
        </SemiWrap>
      </ContentWrap>
    </CMSection>
  );
};

const ArrowRight = styled(FiArrowRight)`
  margin-left: 8px;
  @media ${devices.tablet} {
    display: none;
  }
`;

const ContentWrap = styled.div<IContent>`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  padding: ${(props) => props.padding};
  display: ${(props) => props.display};
  gap: ${(props) => props.gap};
  background-color: ${(props) => props.backgroundColor};
  margin: ${(props) => props.margin};
  text-align: ${(props) => props.textAlign};
  margin: ${(props) => props.margin};
  border-radius: ${(props) => props.borderRaduis};
  justify-content: ${(props) => props.justifyContent};
  align-items: ${(props) => props.alignItems};
  overflow: ${(props) => props.overFlow};
  position: ${(props) => props.position};
  background: linear-gradient(
    180deg,
    #ffede8 0%,
    rgba(255, 237, 232, 0.48) 37.13%,
    rgba(255, 237, 232, 0) 100%,
    rgba(255, 237, 232, 0) 100%
  );
  @media ${devices.tablet} {
    width: 100%;
    /* height: 702px; */
  }
`;

const AbsoluteImage = styled.div<TAbsolute>`
  /* position: absolute; */
  left: ${(props) => props.left};
  top: ${(props) => props.top};
  right: ${(props) => props.right};
  bottom: ${(props) => props.bottom};
  z-index: ${(props) => props.zIndex};
  transform: ${(props) => props.transform};
  @media ${devices.tablet} {
    width: 100%;
    height: 100%;
    z-index: -9999;
    display: none;
  }
  height: 440px;
`;

const MobileImg = styled(Image)`
  display: none;
  @media ${devices.tablet} {
    display: block;
  }
`;

const PcImg = styled(Image)`
  display: block;
  position: absolute;
  left: 40%;
  top: -40%;
  @media ${devices.tablet} {
    display: none;
  }
`;

const SmallTitle = styled.div`
  color: ${({ theme }) => theme.commonColor.pink004};
  font-family: 'Regular';
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px; /* 125% */
  letter-spacing: -0.6px;
  @media ${devices.tablet} {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 18.587px; /* 103.261% */
    letter-spacing: -0.372px;
    margin-bottom: 10px;
  }
`;

const SmallDescription = styled.div`
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 160% */
  letter-spacing: -0.3px;
  font-family: 'Regular';
  margin-top: 65px;
  @media ${devices.tablet} {
    font-size: 16px;
    display: none;
  }
`;

const MobileSmallDescription = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: -0.6px;
  display: none;
  margin-top: 20px;
  @media ${devices.tablet} {
    display: block;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: -0.6px;
  }
`;

const BigTitle = styled.div`
  margin-top: 24px;
  color: #343434;
  font-size: 47px;
  font-style: normal;
  font-weight: 500;
  line-height: 65px; /* 138.298% */
  letter-spacing: -1.41px;
  font-family: 'NOTO';
  @media ${devices.tablet} {
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 38px; /* 135.714% */
    letter-spacing: -0.84px;
  }
`;

const MyWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 400px;
  gap: 60px;
  margin-top: 120px;
  @media ${devices.tablet} {
    gap: 30px;
    margin-top: 45px;
    img {
      width: 45%;
    }
  }
`;

const SemiWrap = styled.div`
  position: relative;
  width: 1200px;
  margin: 0 auto;
  padding: 120px 0;
  @media ${devices.tablet} {
    width: 100%;
    padding: 80px 20px;
  }
`;

const MobileImgWrap = styled.div`
  display: none;
  @media ${devices.tablet} {
    margin-top: 44px;
    display: block;
    position: relative;
    height: 280px;
  }
`;

export default LiveBloodContent;
