import CMMainText from '@src/components/Atoms/CMMainText';
import CMSection from '@src/components/Atoms/CMSection';
import {
  IContent,
  IImageWrap,
  TAbsolute,
  TFlexITem,
} from '@src/interfaces/Introduction';
import { IContent as DContent } from '@src/interfaces/community';
import { devices, theme } from '@src/styles/theme';
import React from 'react';
import styled from 'styled-components';
import { FiArrowRight } from 'react-icons/fi';
import Image from 'next/image';

const DonationContent = () => {
  return (
    <CMSection>
      <ContentWrap
        width="100%"
        textAlign="left"
        margin="0 auto"
        position="relative"
        height="1056px"
      >
        <SemiWrap>
          <SmallTitle>헌혈요청</SmallTitle>
          <BigTitle>
            당신의 따뜻한 도움이
            <br /> 세상을 구합니다
          </BigTitle>
          <div style={{ position: 'relative' }} data-aos="fade-right">
            <MobileImg
              width={204}
              height={361}
              alt={'mobile-example'}
              src={'/imgs/info/thirdMobileLeft2.svg'}
            />
            <MobileImg
              width={214}
              height={139}
              alt={'mobile-phone'}
              style={{
                position: 'absolute',
                top: '30%',
                right: 0,
              }}
              src={'/imgs/info/thirdMobRight.svg'}
            />
          </div>
          <FlexWrap>
            <SmallDescription href={'/community/donation'}>
              채팅과 공유를 통해 쉽고 빠르게 도움을 주세요.
              <br />
              도움이 필요하다면 사연을 작성해보세요
            </SmallDescription>
            {/* TODO: 위아래 정렬맞추기 */}
            <ArrowRight />
          </FlexWrap>
          <AbsoluteImage data-aos="fade-right" left="55%" top="16%">
            <PcImg
              width={524}
              height={927}
              alt="example"
              src={'/imgs/info/thirdPhone.svg'}
            />
          </AbsoluteImage>
          <AbsoluteImage data-aos="fade-right" left="26%" bottom="-100%">
            <PcImg
              width={420}
              height={310}
              alt="phone"
              src={'/imgs/info/thirdExample.svg'}
            />
          </AbsoluteImage>
        </SemiWrap>
      </ContentWrap>
    </CMSection>
  );
};

const ArrowRight = styled(FiArrowRight)`
  margin-left: 8px;
  color: ${({ theme }) => theme.commonColor.white};
  @media ${devices.tablet} {
    display: none;
  }
`;

const ContentWrap = styled.div<IContent>`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  padding: ${(props) => props.padding};
  display: ${(props) => props.display};
  gap: ${(props) => props.gap};
  background-color: ${(props) => props.backgroundColor};
  margin: ${(props) => props.margin};
  text-align: ${(props) => props.textAlign};
  margin: ${(props) => props.margin};
  border-radius: ${(props) => props.borderRaduis};
  justify-content: ${(props) => props.justifyContent};
  align-items: ${(props) => props.alignItems};
  overflow: ${(props) => props.overFlow};
  position: ${(props) => props.position};
  background-image: url('/imgs/info/thirdBackImg.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  @media ${devices.tablet} {
    width: 100%;
    background-image: url('/imgs/info/thirdMobileBackImg.png');
    height: 767px;
  }
`;

const SemiWrap = styled.div`
  position: relative;
  width: 1200px;
  margin: 0 auto;
  padding-top: 120px;
  @media ${devices.tablet} {
    width: 100%;
    padding: 80px 20px;
  }
`;

const AbsoluteImage = styled.div<TAbsolute>`
  position: absolute;
  left: ${(props) => props.left};
  top: ${(props) => props.top};
  right: ${(props) => props.right};
  bottom: ${(props) => props.bottom};
  z-index: ${(props) => props.zIndex};
  transform: ${(props) => props.transform};
  @media ${devices.tablet} {
    width: 100%;
    height: 100%;
    z-index: -9999;
    display: none;
  }
`;

const MobileImg = styled(Image)`
  display: none;
  @media ${devices.tablet} {
    display: block;
  }
`;

const PcImg = styled(Image)`
  @media ${devices.tablet} {
    display: none;
  }
`;

const SmallTitle = styled.div`
  color: ${({ theme }) => theme.commonColor.pink004};
  font-family: 'Regular';
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px; /* 125% */
  letter-spacing: -0.6px;
  @media ${devices.tablet} {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 18.587px; /* 103.261% */
    letter-spacing: -0.372px;
    margin-bottom: 10px;
  }
`;

const FlexWrap = styled.div`
  margin-top: 65px;
  @media ${devices.tablet} {
    margin-top: 25px;
  }
`;

const SmallDescription = styled.a`
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 160% */
  letter-spacing: -0.3px;
  color: ${({ theme }) => theme.commonColor.white};
  font-family: 'NOTO';
  cursor: pointer;
  @media ${devices.tablet} {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: -0.6px;
  }
`;

const BigTitle = styled.div`
  margin-top: 24px;
  color: ${({ theme }) => theme.commonColor.white};
  font-size: 47px;
  font-style: normal;
  font-weight: 500;
  line-height: 65px; /* 138.298% */
  letter-spacing: -1.41px;
  font-family: 'NOTO';
  @media ${devices.tablet} {
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 38px; /* 135.714% */
    letter-spacing: -0.84px;
    margin-bottom: 50px;
  }
`;

export default DonationContent;
