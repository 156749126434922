import CMSection from '@src/components/Atoms/CMSection';
import React from 'react';
import styled from 'styled-components';
import { devices, theme } from '@src/styles/theme';
import CMMainText from '@src/components/Atoms/CMMainText';
import { ITitle } from '@src/interfaces/Introduction';

const SixthContent = () => {
  const flow = '/imgs/flow.svg';
  const social = '/imgs/social.jpeg';
  const talkplus = '/imgs/talkPlus.png';
  const withwork = '/imgs/withWork2.png';
  const gnPharm = '/imgs/gnpharm.png';
  const dataNuget = '/imgs/dataNuget.png';
  const brianImpact = '/imgs/brianImpact.png';
  const sunTree = '/imgs/sunTree.jpeg';
  return (
    <CMSection
      backgroundColor={theme.commonColor.white}
      paddingBottom="100px"
      paddingTop="100px"
    >
      <ContentWrap>
        <CategoryTitle>함께하는 단체</CategoryTitle>
        <Title>피플과 함께하고 있습니다</Title>
        <TitleMobile>
          피플과 <br /> 함께하고 있습니다
        </TitleMobile>
        <PhotoWrap>
          <PhotoUl>
            <PhotoLl>
              <img src={flow} />
            </PhotoLl>
            <PhotoLl>
              <img src={social} />
            </PhotoLl>
            <PhotoLl>
              <img src={talkplus} />
            </PhotoLl>
            <PhotoLl>
              <img src={withwork} />
            </PhotoLl>
            {/* <PhotoLl>
              <img src={gnPharm} />
            </PhotoLl> */}
            <PhotoLl>
              <img src={dataNuget} />
            </PhotoLl>
            <PhotoLl>
              <img src={brianImpact} />
            </PhotoLl>
            {/* <PhotoLl>
              <img src={sunTree} />
            </PhotoLl> */}
          </PhotoUl>
        </PhotoWrap>
      </ContentWrap>
    </CMSection>
  );
};

const ContentWrap = styled.div`
  width: 1200px;
  margin: 0 auto;
  @media ${devices.tablet} {
    padding: 0 20px;
    width: auto;
  }
`;

const PhotoWrap = styled.div`
  width: auto;
`;

const PhotoUl = styled.ul`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
  padding-top: 8px;
  @media ${devices.tablet} {
  }
`;
const PhotoLl = styled.li`
  position: relative;
  text-align: center;
  height: 96px;
  width: 33%;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 100px;
  }
  @media ${devices.tablet} {
    width: 50%;
    img {
      width: 80px;
    }
  }
`;

const CategoryTitle = styled.div`
  color: ${theme.commonColor.pink004};
  font-weight: 700;
  font-size: 22px;
  @media ${devices.tablet} {
    font-family: 'Regular';
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 18.587px; /* 103.261% */
    letter-spacing: -0.372px;
  }
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 48px;
  margin-top: 20px;
  font-family: 'NOTO';
  display: block;
  @media ${devices.tablet} {
    display: none;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 38px; /* 135.714% */
    letter-spacing: -0.84px;
  }
`;

const TitleMobile = styled.div`
  font-weight: 700;
  font-size: 48px;
  margin-top: 20px;
  font-family: 'NOTO';
  display: none;
  @media ${devices.tablet} {
    display: block;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 38px; /* 135.714% */
    letter-spacing: -0.84px;
  }
`;

export default SixthContent;
